<template>
    <div class="pay">
        <headBack>
            <template v-slot:title>
                <div class='title'>
                    下单并支付
                </div>
            </template>
        </headBack>
        <div class='header_cont'></div>
        <div class='pay_top'>
            <div class='top_dec'>
                <img src='https://static.yihu365.cn/img/h5Img/assets/img/home/pay_icon.png' class='pay_icon'/>
                <div>
                    <div class='top_name'>请您在30分钟内完成支付</div>
                    <div class='top_num'>附近有{{nurseNum}}名专业护士可提供服务</div>
                </div>
            </div>
        </div>
        <div class='pay_content'>
            <div class='pay_list'>
                <div>预约套餐</div>
                <div>￥{{ fee }}</div>
            </div>
            <div class='pay_list'>
                <div>代金券</div>
                <div class='pay_right' @click='popActive'>
                    <div>{{couponText}}</div>
                    <van-icon name="arrow" v-if='couponList.length'/>
                </div>
            </div>
            <div class='pay_list bg'>
                <div>
                    <div class='pay_left_name'>余额支付（当前余额:￥{{user.ableUseFee}}）</div>
                    <div class='pay_left_tips'>为了更好的服务您 我们会优先选择余额支付</div>
                </div>
                <van-switch v-model="checked" active-color="#00C291" size='25' :disabled='user.ableUseFee?false:true'/>
            </div>
        </div>
        <div class='btn_btm_cont'>
            <div class='btn_inner'>
                <div class='money'><span class='small'>实际支付：¥</span>{{totle}}</div>
                <van-button type="success" color='#00C291' class='submit_btn' round @click='chosePayment'>立即支付</van-button>
            </div>
        </div>

        <van-popup
            v-model:show="couponShow"
            position="bottom"
            closeable
            >
            <div class='concel_title'>选择代金劵</div>
            <van-radio-group v-model="coupon_radio">
                <div  v-for='(item,index) in couponList' :key='index' class='pop_item' @click='check_radio(index)'>
                    <div>
                        <van-radio :name="index" checked-color="#00C291"></van-radio>
                    </div>
                    <div class='list'>
                        <div class='list_money'>
                            <span>¥</span>{{item.price}}
                        </div>
                        <div class='dero_cont'>
                            <div>
                                <div class='span'></div>{{item.remark}}</div>
                            <div>
                                <div class='span'></div>{{item.priceLimitDesc}}</div>
                            <div>
                                <div class='span'></div>{{item.endTimeDesc}}</div>
                        </div>
                    </div>
                </div>
            </van-radio-group>
            <div class='btm_btn_cont'>
                <van-button type="success" color='#00C291' class='submit_btn' round block @click='nousercoupon'>不使用代金劵</van-button>
            </div>
        </van-popup>


    </div>
</template>
<script>
import { onMounted, ref, computed } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Dialog ,Toast} from 'vant'
import loginApi from '@axios/login'
import jsonp from '@assets/js/jsonp'


export default {
  name: 'pay',
  components: {


  },
  setup () {
    let router = useRouter();
    const store = useStore();
    const route = useRoute();
    let checked = ref(false);
    let couponShow = ref(false);

    let fee = ref(0);
    let coupon_radio = ref('');
    fee.value = route.query.fee;

    let nurseNum = ref(0);

    const getnursenum = (point,city) => {
        let formData = new FormData();
        formData.append('data',JSON.stringify({
            function : 'getNurseCount',
            _from : 'h5',
            token : store.state.token,
            _cityCode : city,
            _lon : point.lng,
            _lan : point.lat,
            orderId : route.query.orderId,
            version : store.state.version,
            newversion : store.state.newversion,
            source : 'h5_users_002'

        }))

        formData.append('encryption',false)

        loginApi.loginNoload(formData).then((res)=>{
            nurseNum.value = res.data.content;
        })
    }

    // var geolocation = new BMap.Geolocation();

    // geolocation.getCurrentPosition(function (r) {
    //     if (this.getStatus() == BMAP_STATUS_SUCCESS) {
    //         jsonp(`https://api.map.baidu.com/reverse_geocoding/v3/?ak=uoyXSaX5BoRlqIFLTUy0zW5KgjTPZ85q&output=json&location=${r.point.lat},${r.point.lng}&callback=baiduCallBack`).then((res)=>{
    //             console.log(res)
    //             getnursenum(r.point,res.result.cityCode);
    //         })

    //     }else{
    //
    //     }
    // }, {enableHighAccuracy: true});
    nurseNum.value = parseInt(Math.random()*100)
    let user = ref({});
    let couponList = ref([]);

    const getUserInfo = () => {
        let formData = new FormData();
        formData.append('data',JSON.stringify({
            function : 'getUserInfo',
            _from : 'h5',
            token : store.state.token,
            login_userId_base : store.state.uid,
            source : 'h5_users_002',
            version : store.state.version,
            newversion : store.state.newversion,
        }))

        formData.append('encryption',false)

        loginApi.loginAction(formData).then((res)=>{
            user.value = res.user
        })

    }

    const getCouponList = () => {
        let formData = new FormData();
        formData.append('data',JSON.stringify({
            function : 'myVoucher',
            _from : 'h5',
            userId : store.state.uid,
            minRow : '1',
            maxRow : '100',
            getAccess : '',
            isEnable : '0',
            serviceCode : route.query.serviceCode,
            roleCode : route.query.roleCode,
            orderId : route.query.orderId,
            serviceType : '',
            amount : fee.value,
            source : 'h5_users_002',
            version : store.state.version,
            newversion : store.state.newversion,
        }))

        formData.append('encryption',false)

        loginApi.loginAction(formData).then((res)=>{
            couponList.value = res.data.list;
        })
    }

    getCouponList();
    getUserInfo();


    let totle = computed(()=>{
        let sum = parseFloat(fee.value);

        if(coupon_radio.value !== ''){
            sum -= parseFloat(couponList.value[coupon_radio.value].price)
        }

        if(checked.value){
            if(sum > user.value.ableUseFee){
                sum -= parseFloat(user.value.ableUseFee)
            }else{
                sum = 0;
            }

        }

        return sum;
    })

    let popActive = () => {
        if(couponList.value.length == 0){
            return;
        }
        couponShow.value = true;
    }

    let nousercoupon = () => {
        coupon_radio.value = '';
        couponShow.value = false;
    }

    let check_radio = (index) => {
        coupon_radio.value = index;
        couponShow.value = false;
    }

    let couponText = computed(()=>{

        if(couponList.value.length == 0){
            return '暂无可用代金卷'
        }
        if(coupon_radio.value === ''){
            return '请选择代金卷'
        }

        return '¥ ' + couponList.value[coupon_radio.value].price
    })

    const balancePay = () => {
        let voucherId = '';
        let paynum = route.query.fee;
        if(coupon_radio.value !== ''){
            voucherId = couponList.value[coupon_radio.value].voucherId;
            paynum -= couponList.value[coupon_radio.value].price
        }


        let formData = new FormData();
        formData.append('data',JSON.stringify({
            function : 'payOrder',
            userid : store.state.uid,
            orderId : route.query.orderId,
            payFee : paynum,
            voucherId : voucherId,
            _from : 'h5',
            token : store.state.token,
            version : store.state.version,
            newversion : store.state.newversion,
            source : 'h5_users_wx_101',
        }))

        formData.append('encryption',false);
        loginApi.loginAction(formData).then((res)=>{
          Toast.loading({
            message:'页面跳转中...',
            duration:3000
          })
          setTimeout(()=> {
            router.push({
              name : 'orderList'
            })
            Toast.clear()
          },3000)

        })
    }

    const isWx = () =>{
        let ua = window.navigator.userAgent.toLowerCase();
        return (ua.match(/MicroMessenger/i) == 'micromessenger') ? true : false;
    }
    const chosePayment = () => {
        if(isWx()){//微信浏览器
            //小程序环境判断
            wx.miniProgram.getEnv((res)=>{
                if(res.miniprogram){

                    // alert("在小程序里iii")
                    console.log('小程序里')
                    wx.login({
                        success:res =>{
                            if (res.code) {
                               alert(res.code)
                            } else {
                                alert(res.errMsg)
                                console.log('登录失败！' + res.errMsg)
                            }
                        },
                        complete:res =>{
                            console.log(res);
                            alert(res)
                        }
                    })
                }else{
                    payAction();//微信公众号支付
                }
            })

        }else{//非微信浏览器
            payH5Action()
        }
    }
    //H5支付
    const payH5Action = () =>{
        const source = store.state.sourceMark;
        let formData = new FormData();
        var gate_id = '-1';
        if(store.state.sid == '88000000009'){
            gate_id = '100';
        }
        let voucherId = '';
        if(coupon_radio.value !== ''){
            voucherId = couponList.value[coupon_radio.value].voucherId
        }

        if(!totle.value){
            //余额支付
            balancePay();
            return;
        }
        let redirectUrl ='';
        if(source == '001'){//001是安卓和IOS
           redirectUrl = encodeURIComponent("http://m.yihu365.cn/public.shtml")
        }else{
           redirectUrl = encodeURIComponent("https://mi.yihu365.cn/orderList")
        }

        formData.append('data',JSON.stringify({
            function : 'recharge',
            source : 'h5_users_002',
            user_id : store.state.uid,
            fee : totle.value + '',
            pay_type : '261',
            voucherId : voucherId,
            order_id : route.query.orderId,
            gate_id : '1',
            app_flag : '3',
            device_id : 'h5',
            h5PayReturnUrl:redirectUrl,
            order_type:'1',
            _from : 'h5',
            token : store.state.token,
            userid : store.state.uid,
            userId : store.state.uid,
            version : store.state.version,
            newversion : store.state.newversion,
            sid : store.state.sid,
        }))
        formData.append('encryption',false)
        loginApi.loginAction(formData).then((res)=>{
            window.location.href = res.data;
        })
    }
    //微信支付

    const payAction = () => {
        let formData = new FormData();
        var gate_id = '-1';
        if(store.state.sid == '88000000009'){
            gate_id = '100';
        }
        let voucherId = '';
        if(coupon_radio.value !== ''){
            voucherId = couponList.value[coupon_radio.value].voucherId
        }

        if(!totle.value){
            //余额支付
            balancePay();
            return;
        }

        formData.append('data',JSON.stringify({
            function : 'recharge',
            source : 'h5_users_wx_101',
            user_id : store.state.uid,
            fee : totle.value + '',
            pay_type : '45',
            gate_id : gate_id,
            voucherId : voucherId,
            sid : store.state.sid,
            app_flag : '3',
            device_id : 'h5',
            order_id : route.query.orderId,
            _from : 'h5',
            token : store.state.token,
            userid : store.state.uid,
            userId : store.state.uid,
            version : store.state.version,
            newversion : store.state.newversion,
            openid : store.state.openId
        }))

        formData.append('encryption',false)

        loginApi.loginAction(formData).then((res)=>{
            let result = JSON.parse(res.data)
            WeixinJSBridge.invoke(
                'getBrandWCPayRequest', {
                    "appId":result.appId,     //公众号ID，由商户传入
                    "timeStamp":result.timeStamp,         //时间戳，自1970年以来的秒数
                    "nonceStr":result.nonceStr, //随机串
                    "package":result.package,
                    "signType":result.signType,         //微信签名方式：
                    "paySign":result.paySign //微信签名
                },
                function(res){
                    if(res.err_msg == "get_brand_wcpay_request:ok" ){
                        router.push({
                            name : 'orderList'
                        })
                    }
                    if(res.err_msg == "get_brand_wcpay_request:cancel" ){
                        router.push({
                            name : 'orderList'
                        })
                    }
                    if(res.err_msg == "get_brand_wcpay_request:fail" ){
                        router.push({
                            name : 'orderList'
                        })
                    }
                }
            );
        })

    }

    return {
        payAction,
        chosePayment,
        payH5Action,
        couponText,
        check_radio,
        coupon_radio,
        nousercoupon,
        popActive,
        couponShow,
        couponList,
        totle,
        fee,
        nurseNum,
        user,
        router,
        checked
    }
  },

}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
    .btm_btn_cont{
        padding:30px;
    }
    .concel_title{
        font-size: 30px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        text-align: center;
        line-height: 100px;
        height: 100px;
        border-bottom: 1px solid #EDEDED;
    }
    .pop_item{
        display: flex;
        justify-content: space-between;
        padding:15px 30px;
        align-items: center;
    }
    .bg{
        background: #F7F7F7;
        border-radius: 10px;
        padding:23px 15px;
    }
    .pay_left_name{
        font-size: 28px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
    }
    .pay_left_tips{
        font-size: 24px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #333333;
        margin-top:12px;
    }
    .pay_top{
        width: 100%;
        height: 200px;
        background: url(https://static.yihu365.cn/img/h5Img/assets/img/home/pay_top.png) no-repeat center center;
        background-size:100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .top_dec{
            display: flex;
            .pay_icon{
                width: 66px;
                height: 66px;
                margin-right:15px;
            }
        }
        .top_name{
            font-size: 32px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
        }
        .top_num{
            font-size: 24px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: #FFFFFF;
        }
    }
    .pay_content{
        padding:58px 47px;
        .pay_list{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 28px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            margin-bottom: 35px;
        }
    }
    .pay_right{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: #999999;
    }
    .btn_btm_cont{
        width: 100%;
        position: fixed;
        bottom: 0;
        left:0;
        padding:20px 0;
        display: flex;
        justify-content: flex-end;
        background: #fff;
        z-index: 999;
        border-top:1px solid #f5f5f5;
        .btn_inner{
            padding:0 30px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        .order_btn{
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            height: 50px;
            padding:0 22px;
            line-height: 50px;
            border-radius: 25px;
            border: 1px solid #999999;
            margin-left:20px;
        }
        .money{
            margin-right:40px;
            color:#FF6161;
            font-size: 40px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
        }
        .small{
            font-size:28px;
            margin:0 5px;
        }
        .submit_btn{
            width: 200px;
            height: 80px;
            line-height: 80px;
            white-space: nowrap;
        }
    }


    .list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 80px;
        overflow: hidden;
        .list_money {
            font-size: 70px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: #ffffff;
            span {
            font-size: 30px;
            margin-right: 15px;
            }
        }
    }
    .list {
        margin-bottom: 20px;
        height: 160px;
        border-radius: 10px;
        background: url(https://static.yihu365.cn/img/h5Img/assets/img/my/coupon_bg.png) no-repeat center center;
        background-size: 100%;
        font-size: 34px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #313131;
        margin-left:20px;
    }
    .dero_cont {
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin-left: 34px;
        & > div {
            position: relative;
        }
        .span {
            width: 10px;
            height: 10px;
            border: 1px solid #fff;
            border-radius: 6px;
            position: absolute;
            left: -20px;
            top: 10px;
        }
    }
</style>
