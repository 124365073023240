export default function(url){
    return new Promise((resolve, reject) => {
                window.baiduCallBack =(result) => {
                    resolve(result)
                }
                const JSONP = document.createElement('script');
                JSONP.type = 'text/javascript';
                JSONP.src = url;
                document.getElementsByTagName('head')[0].appendChild(JSONP);
                setTimeout(() => {
                    document.getElementsByTagName('head')[0].removeChild(JSONP)
                },500)
     })
}